<template>
   <ObiText :as="as" :type="type" v-bind="attrs" v-on="$listeners" :class="classList" :disabled="loading">
      <IconLoading v-if="loading" width="18" height="18" />
      <ObiIcon class="obi-button__icon" :icon="icon" v-if="!loading && icon && !iconRight" />
      <span class="obi-button--text" v-if="text && !loading" v-html="text" /> <slot v-else />
      <ObiIcon class="obi-button__icon" :icon="icon" v-if="!loading && icon && iconRight" />
   </ObiText>
</template>

<script>
import { omit, keys } from 'lodash';
import IconLoading from '@/components/Icons/general/IconLoading';

export default {
   name: 'ObiButton',

   components: {
      IconLoading,
   },

   props: {
      as: {
         type: String,
         default: 'button',
      },
      type: {
         type: String,
         default: 'button',
      },
      active: {
         type: Boolean,
         default: false,
      },
      text: {
         type: String,
      },
      icon: {
         type: String,
      },
      iconRight: {
         type: Boolean,
         default: false,
      },
      iconHidden: {
         type: Boolean,
         default: false,
      },
      color: {
         type: String,
         default: 'primary',
      },
      size: {
         type: String,
      },
      outline: {
         type: Boolean,
         default: false,
      },
      wider: {
         type: Boolean,
         default: false,
      },
      block: {
         type: Boolean,
         default: false,
      },
      loading: {
         type: Boolean,
         default: false,
      },
      close: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      classList() {
         if (this.close) {
            return 'btn-close';
         }

         return [
            'obi-button btn',
            this.block && `w-100`,
            this.active && `active`,
            this.wider && `btn-wider`,
            this.size && `btn-${this.size}`,
            this.icon && `obi-button--icon`,
            this.iconHidden && `--icon-hidden`,
            this.loading && `obi-button--loading`,
            this.color && `btn-${this.outline ? 'outline-' : ''}${this.color}`,
         ];
      },

      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-button {
   &.obi-button--icon {
      grid-gap: 0.5rem;
      align-items: center;
      display: inline-grid;
      grid-auto-flow: column;

      &.--icon-hidden {
         .obi-button__icon {
            display: none;
         }
      }
   }

   &.obi-button--loading {
      align-items: center;
   }

   .obi-button__icon {
      line-height: 1;
      font-size: 16px;
      display: inline-flex;
   }
}
</style>
